// data/text.js

export const textData = [
    {   
      showEnglish: false,
      title: '윤태준의 사진적 여정 : 감각의 확장을 위하여',
      content: `<p>윤태준은 2013년부터 현재까지 시각 매체에 대한 다양한 탐색을 보여주는 사진 작품들을 발표해왔다. 그는 사진의 존재론부터 매체의 확장까지 사진에 대한 사유를 넓혀 나갔으며, 최근에는 디지털 환경에서 감각적 표현의 가능성을 모색하고 있다. <회한의 무게》부터 〈미들턴》까지 사진의 작업 내용과 작업 방식 그리고 전시 스타일은 각기 다르지만, 거의 모든 시리즈를 관통하는 한 가지는 '돌'을 소재로 삼고 있다는 점이다. 윤태준의 작업에서 돌은 사진의 객관적 기록성을 대변하기도 하고, 사진 재현에 대한 진실성에 균열을 가하기도 하며, 디지털의 공간에서 현실과 가상을, 물질과 감각을 매개하기도 한다. 그리하여 여기에서는 윤태준의 '돌의 궤적을 중심으로 작업의 양상을 살펴보고, 사진적 여정을 따라가 보고자 한다. </p>
      <p>윤태준의 2013~2014년 작 〈회한의 무게》는 얼음이 녹고 있는 일상적 사물들을 기록한 사진 연작이다. 작가는 시계, 사진, 돌, 신발, 명찰, 일기장 등 과거의 특별한 기억, 의미 있는 경험을 떠올리게 하는 물건들을 얼렸다. 작가는 마치 사진이 지속하는 시간과 변모하는 공간의 한 단면을 절단해 평면의 프레임 안으로 고정하는 것처럼, 물질과 기억을 얼음 속으로 얼려 가두고 싶었다. 그리하여 돌이킬 수 없는 시간의 파괴로부터 그 경험을 안전하게 보호하고자 했다. 망각의 위기에서 그 기억을 지켜내고자 했다. 그러나 얼음은 무엇보다 불안전한 보존 장소였다. 결빙과 해빙을 거치며 사물의 형태는 변형, 변질되어 본래의 모습을 잃어버렸다. 시계는 멈췄고 사진은 변색 되었으며 잉크가 번진 일기장은 더는 그 시간을 동일하게 회상해 줄 수 없었다. 시간이 팽창과 수축의 물리적 운동을 통해 물질에 변형을 가한 것처럼, 기억에도 영향을 끼쳤다. 기억 자체가 시간성을 포함하고 있기 때문이다. 기억은 발생한 시점과 그것을 불러오는 시점 사이에 발생되는 시간차 때문에, '변형, 치환, 전도 내지는 복구' 등의 기억 왜곡이 생기게 마련이다.<br/>
      <p>한 기억학자는 이것을 '활력의 기억'이라 말한다. 기억은 입력값과 출력값이 동일한 컴퓨터 파일이 아니므로 기계적으로 재생되지 않으며, 새롭게 생산되는 정신의 활동인 것이다. 작가가 진정으로 기록하고자 한 것은 이러한 기억의 불완전성이었다. 그는 기억이란 고정된 장면이나 시간이 아니라, 시간의 지속성 안에서 유동하는 것임을, 그것을 기록할 때 또 다른 의미가 생성되는 것임을 얼음의 유약함으로 보여주고자 한 것이다. <회한의 무게>는 시간이 지날수록 줄어드는 얼음의 무게 혹은 사물의 변형과 더불어 불완전한 기억과 시간에 대한 사유를 사진적인 방식으로 풀어내고 있다.
      〈회한의 무게>는 카메라 앞의 대상을 기계적으로 재현한 사진이다. 사진으로 포착된 돌과 얼음 덩어리, 그리고 녹아 흐르는 물의 이미지는 프랑스의 철학가이자 비평가 롤랑 바르트(Roland Barthes, 1915-1980)가 명명한 '과거에 존재했음(ca-a-ete)'을 증언한다. 그러나 사진의 진실성에 대한 일반의 믿음은 어디에서 오는가? 사진의 가치는 어떻게 형성되는가? 윤태준은 사진의 증언적 지위에 의문을 제기한다. 
      </p>
      <p>2015년 작 〈북두칠낙>은 사진의 진실성, 증언가치라는 개념에 대해 해체를 시도한 프로젝트이다. 작가는 박물관의 학예사나 고고학자 혹은 민족지학자의 역할을 수행했다. 우선 그는 문헌상으로 존재했다고 여겨진 인물 '낙씨'에 대한 자료들을 조사했다. 그다음 그에 대한 역사를 환기하는 유물들과 관련 서사를 사진으로 재현하고 이를 아카이브 형식으로 재구성했다. <낙씨의 탄생바위>와 같이 화면의 중앙에 자리한 바위의 사진이나 일련번호가 매겨진 돌 조각 등은 고고학적인 맥락에 의해 전설의 장소 그리고 역사적 유물인 것으로 제시되었다.
      <p>미국의 문예평론가이자 사회운동가 수잔 손탁(Susan Sontag, 1933-2004)은 "사진을 찍는다는 것이 곧 대상을 중시한다는 것"임을 지적한 바 있다. 등록 번호가 붙은 돌 조각이 카메라 앞의 대상이 되어 사진으로 기록된 순간, 널린 자연물의 하나에 불과했던 돌 조각은 역사적 인물의 존재를 확증하는 특별한 가치를 부여받는다. 이러한 점을 손탁은 사진의 '고유한 경향'이라 말한다. 그렇지만 윤태준은 <낙씨의 겨드랑이에 나 있는 깃털>과 같이 허구의 이미지를 슬쩍 끼워 넣어 관람객의 믿음에 균열을 가한다. 작가는 사진이 대상을 재현하는 것과 그것이 어떤 가치를 가지게 되는가 혹은 무슨 의미를 전달하는가는 전혀 별개의 문제임을 지적하고 있다. 가상과 허구가 뒤섞인 사진의 재현을 통해 그는 보이는 것을 의심 없이 받아들이는 태도가 관습과 학습에 의해 의식화된 시각임을 인지시킨다. 이는 박물관이나 미술관 같은 제도권에서 사진이 수용되는 방식을 차용하며 어떻게 사진이 역사적 증거로서 가치가 부여되는지 또는 문화로 번역되는지를 역설적으로 보여준 것이다.</p>
      <p>2017년 작 〈환상계단〉은 북서울시립미술관에서 열린 《2017 커뮤니티아트 안녕하세요》(17.4.4.-17.6.25)에 참여한 설치 작업이다. <환상계단>은 추상의 존재를 현실의 이미지로, 물성을 가진 오브제로 치환시킨다. 윤태준은 허구를 현실 속으로 깊이 개입하게 만들어 실상과 허상이라는 상반된 개념의 경계를 보다 모호하게 더욱 불분명하게 만든다. 〈환상계단, 철거민>은 망판 인쇄술로 출력한 것처럼 망점이 찍힌 이미지를 보여준다. 그 형체는 추상적인데, 서로 다른 크기의 망점들이 이미지를 드러낼 듯 감추고 보여줄 듯 은폐해 버리기 때문이다. 손탁에 따르면, 사진은 앞서 언급한 '고유의 경향' 때문에 내용과 상관없이 대상을 '우상화'한다. 사진의 미학적 경향은 대상을 아름다운 이미지'로 변질시킨다. 그리하여 사진에 나타난 고통이나 슬픔 등을 중화시켜 버린다. 그러나 작가는 망점의 장치를 통해 이미지를 모호하게 만들면서 사진의 '우상화'를 차단하고, 고통을 미화시켜 소비하는 사진의 미학적 경향을 경계한다. 한편 〈환상계단, 철거민〉은 비닐 용지로 출력하여 제작 방식에 변화를 주기도 했는데, 그는 이를 계기로 사진과 디지털 매체에 다양한 실험을 가하면서 현대적 시각을 모색하게 된다. </p>
      <p>2019년 이후 윤태준의 사진적 관심은 디지털 기술과 사진의 융합으로 이행한다. 우선 작가에게 디지털 기술은 물질이 가진 본래의 성질, 정체성이라는 개념을 해체할 수 있게 하는 도구이다. 합성과 추출, 모방과 변형 등 가공이 자유로운 디지털 환경에서 세계에 존재하는 모든 개체가 가지는 원본성, 항구성, 불변성이라는 개념은 더는 유효하지 않으며 통용되지도 않는다. 그에게 물질세계는 시공간을 초월하는 것, 가변적인 것, 어떤 형상으로도 변화할 수 있고 어떤 성질과도 결합할 수 있으며 이를 통해 새로운 감각을 일깨우는 것이다. 정보통신혁명, 로봇, 인공지능 등으로 대변되는 기술 진화의 시대에 개체만의 독자적인 형상, 본질은 모조성, 가변성, 복제성에 자리를 내주었다. <낮고, 빠르게 쏘기> <미들턴>은 이러한 개념에 근거한다.</p>
     
      <p>우선 작가가 선택한 돌을 사진으로 촬영하여, 데이터의 세계로 완전히 편입시킨다. 그리고 다양한 이미징 소프트웨어 프로그램들로 돌의 형상과 물성을 이질적이거나 상이한 개체로 탈바꿈시켜 돌이 가진 본래의 정체성을 전복시키고, 전혀 다른 시각적 산물로서 재구성한다. 작가에게 돌은 현실 세계를 대표하는 자연의 산물로서, 현실과 디지털, 물질과 감각이라는 두 이질적 영역을 매개하는 역할로 주어진다. 이러한 디지털 프로세스를 통해 작가는 현실의 사물과 그래픽 이미지 사이의 중간지점에서 시각의 창조적 가능성을 확장시킨다.
      작가는 모든 것이 데이터화되는 가상의 시공간에 '인간적인 경험, 감각을 부여하고자 한 것으로 보인다. 왜냐하면, 추상으로 변모한 이미지는 우리의 지각을 자극하는 세속적이고 자연적인 물질, 물성과 결합하고 있기 때문이다. 이를테면, 구겨진 은박지, 필름, 암석이나 지질의 단면, 물, 거울, 알루미늄, 유리와도 같이 무게나 질감, 특정한 물성이 드러나는 것들이다.
      </p>
      <p>이는 디지털 공간이 제거한 것이 개체의 외형, 형상, 정체성이나 원본성뿐만이 아님을 역설한다. 디지털 기술이 데이터 세상을 확장할수록 함몰되는 것은 감각의 상실에 있다. 자연의 세계가 감각의 공간이라면 디지털 환경은 그 어원(손가락을 뜻하는 '디지트(digit))에서 알 수 있듯이, "클릭과 타이핑에 한정된" 세계이다. 이미지 속에 등장하는 손의 형상은 물질과 감각이 만나는 바로 그 지점을 상기시킨다. 허구의 공간이 팽창할수록 제한되는 것은 우리의 신체 경험, 감각임을 드러낸다. 윤태준의 '디지트' 이미지는 현실과 가상, 자연과 디지털, 물질과 정신이라는 상반된 두 영역을 보다 이질적이고, 환상적으로 결합해, 축소되고 제한된 우리의 감각, 신체성을 회복, 복구 혹은 재발견하고 있다. 현실에 기반한 사진 이미지와 완전히 숫자화 된 디지털 이미지, 그 중간지점을 가리키는 <미들턴>은 빛의 여정, <반딧불이>(2021) 작업으로 '턴' 했다. 사진의 객관적 재현성을 넘어 디지털 프로세스를 통한 다양한 시각을 모색해온 작가의 다음 작업이 어느 지점을 향해 나아갈지, 우리의 경험, 감각을 활짝 열고 따라가 보자. </p>`,
      contentINFO: `사진예술, 인터뷰, 4월호, 2022<br/>
      김소희, 한국사진문화연구소 연구원`
    },
    {   
      showEnglish: false,
      title: 'Low, Quickdraw / Middle Turn',
      content: `
      <p>저것은 돌이다. 하지만 사진 속의 돌은 현실과 달리 납작하다. 저것은 돌이다. 하지만 사진 속의 돌은 현실과 달리 가볍다. 그럼에도, 저것은 돌이다. 사진 속의 돌이 더 이상 납작하지 않고, 가볍지 않은 순간이 찾아온다. 사진을 바라보는 과정은 시각에 의존하지만, 사진 속의 대상을 지각하는 일에는 시각 이외의 다양한 감각과 경험, 기억이 개입되기 때문이다. 사진을 바라보며 기억 속에서 촉감과 질감, 온도와 냄새 등이 동시에 떠오를 때 의심 없이 저것은 돌이다, 인식하게 된다.
      </p>
      <p>
      윤태준은 사진을 찍는 과정에서, 또 찍힌 사진을 바라보는 일에서 소거되거나 덧씌워지는 감각들, 또 결핍되거나 과잉되는 지각을 주목한다. 그는 사진 속의 대상이 품은 의미와 맥락들을 이야기로 연결하고, 그 이야기에 맞게 이미지를 편집하는 방식의 작업에 의문을 품는다. 그 이유는 이미지를 기승전결 식의 전형적인 이야기의 구조와 형태 안에 가두기 때문이다. 윤태준은 작업 <낮고, 빠르게 쏘기 Low, Quickdraw〉 (2019~2020)와 <미들턴 Middle Turn) (2020~)에서 최대한 의미와 이야기를 제거하고, 사진이 생산되고 전달되는 일련의 과정 안에서 수반되는 감각과 인식에 집중한다. 또 시각 매체인 사진 안에 자신이 느낀 다양한 감각을 어떻게 최대한 구현할 수 있는지 고민한다.
      </p>
      <p>
      작품 이미지는 대부분 스튜디오에서 오브제를 촬영하고, 해당 이미지를 프린트해 또 다른 오브제와 병치하거나 이미지 데이터를 3D 그래픽 프로그램으로 불러와 가공해 만들어졌다. 실제로 촬영하는 일보다 촬영 데이터를 재편집하는 데 시간이 더 할애된 제작 과정에는 3D 그래픽을 포함해 다양한 소프트웨어가 사용되었다. 그 까닭에 〈낮고, 빠르게 쏘기>에서 <미들턴>까지 이어지는 작업에는 사진으로 보이는 이미지부터, 사진보다는 그래픽으로 인지되는 이미지까지 포함된다. 사진에서 그래픽까지 다양한 레이어가 쌓인 이미지에서는, 여러 감각을 통해 대상에서 느낀 다양한 물성을 시각 이미지에 구현하려는 작가의 의지를 읽을 수 있다. 또한 사진과 그래픽의 경계가 모호한 작업은, 어떤 이미지를 사진이라고 규정할 때 어떤 감각과 인식이 작동되는 것인지 우리에게 질문을 던지는 것 같다.
      </p>
      `,
      contentINFO: `보스토크, 리뷰, 5월호, 2021<br/>
      박지수, 보스토크 메거진 편잡장`
    },
    {   
      showEnglish: false,
      title: '우리는 어떻게 사진을 믿게 되는가 <북두칠낙>',
      content: `<p>길거리에서 마주친 돌멩이는 발에 치이며 굴러다니지만, 어떤 돌멩이는 손끝 하나 댈 수 없는 유리관에 놓여 유물이 되기도 한다. 만 약 길거리의 돌을 박물관에 몰래 가져다 두면 어떻게 될까? 고고학자나 박물관 학예사가 아니고서야 이를 눈치채지 못할 것이고, 대 부분의 관람객은 그 돌에 어떤 역사적 가치가 있다고 생각할 것이다. 어떤 돌멩이든 스스로 자신이 어떤 가치를 지녔다고 말할 수 없 기 때문이다. 결국 어떤 돌멩이를 유물로 만들어 주는 건 그것에 의미를 부여하는 외부 요소일지도 모른다. 그렇다면 여기서 '돌멩 이'를 '사진'으로 바꾸면 어떨까? 물론 돌과 사진을 두고 유사점을 찾는 건 무의미하다. 하지만 위의 사례처럼 어떤 맥락에 따라 다 르게 읽힐 수 있다는 점에서는 연결될 수 있다. 사진은 때로 그 가벼운 속성 때문에 줏대 없이 나풀거리며 의미가 뒤바뀐다. 이처럼 우리가 윤태준의 '북두칠낙'에서 읽어내야 할 것은 '낙씨에 대한 연구 아카이브'가 아니라, 사진의 '줏대 없음'과 그것이 어떻게 증 거가 되는지에 대한 고민이다
      </p>
      <p>"역사에서 잊혀져 있는 부분을 다시 내세우는데 관심이 있어요. 처음엔 성씨설화에 관심을 가졌고, 자연스럽게 제 성인 윤씨 에 대한 작업을 진행하려고 했죠. 그래서 경기도 파주에 있는 윤씨설화 유적을 찾아갔는데, 이걸 유적이라고 하기엔 너무 초라 한 거에요. 연못 하나, 무슨 산 하나 그게 전부인 거죠. 문득 이게 다른 장소에도 다 통용될 수 있겠다는 생각이 들었어요. 그래 서 조사를 하다 보니 지금은 없어진 성씨 중에 '낙씨'가 있더라고요. 이게 문헌 상으로만 남아있는 건데, 떨어질 낙(※)하고도 연결시킬 수 있을 것 같았어요. 발음상 '낚시'랑 유사한게 재밌기도 하고요(웃음). '북두칠낙'은 이런 사실과 가상을 맞붙여 제가 구상한 세계관으로 낙씨에 대한 설화를 써내려 가는 작업이에요.”
      모락산에서 찾아낸 그럴싸한 기록사진과 관리번호를 새긴 돌멩이, 박물관의 유물 이미지는 형식상 아카이브로 손색이 없다. 하지만 </p>
      </p>
      <p>"낙씨의 겨드랑이에 있는 깃털'처럼 낙씨의 신체를 찍은 (다소 비현실적인)사진들은 이 모든 것이 농담이었음을 고백하는 것 같다.
      바로 이런 지점에서 관객은 '북두칠낙'을 아카이브나 다큐멘터리로 봐야 할지, 혹은 온전한 픽션으로 치부해야 할지 혼란에 빠져든다.
      <p> 
      "일반적으로 어떤 사진이 아카이브에 들어가면 그 자체로 하나의 증거물이 된다고 보는데, 그걸 비틀고 싶었어요. 관객이 이걸 사실이라고 느끼면서도 또 어느 정도는 의심스러운 느낌도 받았으면 해요. 이런 측면에서 가장 고민했던 부분이 전시에서 작품 배치를 어떻게 하느냐였어요. 실제와 허구를 자연스럽게 섞으면서 관객이 이야기를 따라가게 하고 싶었는데, 아무래도 실제 공 간에 설치를 해봐야 감이 잡힐 것 같아 학과 갤러리에 테스트 삼아 전시를 꾸렸죠." 
      </p>
      <p>이처럼 '북두칠낙'에서 다루는 이야기는 완전한 진실도, 허구도 아닌 그 중간의 어디쯤에서 부유한다. 진실과 거짓이 서로의 모습으 로 위장한다고도 볼 수 있다. 어떤 부분은 문헌과 기록에 근거한 사실이지만, 또 어떤 부분은 작가가 꾸며낸 가상이기 때문이다. 하 지만 윤태준이 '북두칠낙'을 통해 짚어내려는 건 진실과 허구를 판별하는 것에 머물지 않는다. 그는 어떤 소재를 자신이 꾸민 세계 관으로 재해석하는 방식에 대해 고민하고, 나아가 사람들이 어떤 과정을 통해 사진을 믿게 되는지 탐구한다.
      </p>
      <p>
      "작업을 진행하면서 위서(총출)나 환단고기(# )와 같은 책이 자신들이 주장하는 역사를 어떻게 사실로 증명하는지 참고 했어요. 특히 환단고기의 경우 이를 강력하게 믿는 분들이 사진을 근거로 내세우는 게 신기했어요. 황당하기도 하고. 그런 맥락 에서 UFOL 귀신 같은 것들도 사실 그것이 찍혔다고 주장하는 사진이 존재하지 않았다면, 애초에 논의 자체가 없었을 것 같거 든요. '북두칠낙' 역시 제가 마음대로 역사 이미지를 재가공한 건데, 이를 통해 다른 이야기가 나오는 게 흥미로워요.
      </p>
      <p>
      윤태준이 그리는 '북두칠낙'의 최종 결과물은 전시가 아니라 책이다. 이미지가 중심이 될 수 밖에 없는 전시의 특성상, 사진과 텍스 트가 밀접하게 연결되는 책의 형식이 그가 의도한 맥락을 보여주기에 가장 효과적이기 때문이다.
      </p>
      <p>
      "사실 전시는 작업을 컴팩트하게 보여주는 '프리뷰'로만 머물 수 있죠. 하지만 책은 내러티브를 명확히 보여줄 수 있기 때문에 의도하는 바를 가장 잘 드러낼 수 있다고 생각해요. 책은 사진집이라기보다는 역사서 같은 단행본에 가까운 형태겠죠. 낙씨열 전 같은 가상의 이야기, 장소에 대한 기록, 낙씨를 데려다 키운 것으로 상정한 외국인의 연보 등을 써뒀어요. 현재 70~80%정 도 완성됐고요. 이게 어떻게 보면 소설 아닌 소설을 쓰는 거죠. 특히 모락산성 관련해 인용한 글은 검증된 사실인데도, 막상 다 른 글이랑 함께 배치하면 그것마저도 픽션처럼 보여서 신기해요. 역사라는 게 생각보다 참 말랑말랑한 것 같아요."
      </p>
      <p> 우리는 어떤 사실이 어떤 책에 실렸다면, 또는 이를 증명하는 사진이 존재한다면 별다른 의심 없이 받아들이곤 한다. 윤태준은 이처 럼 그럴싸한 느낌'을 주는 장치들을 적극적으로 활용하고, 실재와 허구를 유머러스하게 중첩시켜 믿어봄직한 느낌을 극대화한다.
      하지만 결국 '북두칠낙'이 일종의 '퓨전 사극'같은 팩션(Faction) 형식으로 작가가 꾸며낸 세계라는 사실을 고려하면, 우리는 어떤 사진을 볼 때 그 형식이나 권위, 맥락에 너무나 쉽게 속는 아닌지 고민해 봐야 한다.
      </p>`,
      contentINFO: `포토닷, 리뷰, 5월호, 2016<br/>
      이기원`
    },
    {   
      showEnglish: false,
      title: '기억과 망각의 줄다리기 <회한의 무게>',
      content: `
<p>최근 큰 인기를 끌고 있는 디즈니와 픽사의 합작 애니메이션 <인사이드 아웃>은 인간의 머릿속에 살며 다섯 가지 감정을 제어하는 다섯 개의 캐릭터를 통해 기억과 망각의 작용을 재기발랄하게 표현했다. 특히 애틋한 기억이 사라지는 장면은 여러 관객을 눈물짓게 하며 지난날들을 회상토록 했다. 하지만 망각 덕분에 영화가 해피엔딩에 도달하듯, 기억과 망각의 팽팽한 줄다리기는 인간의 삶에서 없어서는 안 될 동력이 되기도 한다.  </p>
<p>윤태준의 사진작품 시리즈 <회한의 무게>(2013~2014)는 바로 그 긴장감의 순간을 포착하고 있다. 작가는 작업노트에서 “기억은 하나의 특정한 시간과 때가 아니라 흐르는 시간 속에서 함께 움직인다. 아주 사소한 기억들이 나의 주위에 살아 있고 함께 있다. 특별한 사물, 또는 특정한 시간만이 의미 있는 것은 아니었다. 잊혀진 것들과 주위에 있는 것들 또한 나의 한 부분이었다”고 말한 적이 있다. 그는 자신의 반려견이 죽고 나서 그 사체를 묻은 돌무덤을 바라보면서 슬픔이나 상실감에 앞서 어떤 과거의 기억이 다시 떠오르는 순간의 미묘한 감정을 느끼게 됐다. 소중한 기억을 간직하려는 인간의 욕망을 표현하기 위해 일기장, 사진, 편지, 군화, 이름표, 화분, 시계 등 지극히 개인적인 추억이 담긴 물건을 물에 담아 그것을 얼려 사진으로 촬영했다. 작가는 얼음에 둘러싸인 피사체 그 자체를 부각시키면서도 그것과 가장 잘 어울리는 공간을 찾는 데도 많은 노력을 기울였다. 그리고 의도적으로 얼음이 조금 녹은 상태에서 촬영을 진행했다. </p>
<p>그의 작품에는 상온에서 얼음이 물로 돌아가듯 어쩔 수 없이 조금씩 허물어져 가는 망각의 순간이 나타난다. 영원할 수 없기에 더욱 붙들고 싶은 기억에 대한 간절함 또한 묻어난다. 얼음에 얽혀 묻힌 반려견의 돌무덤을 촬영한 <Dog’s Grave>에서 시작해 여자 친구에게 받은 편지를 얼린 <A Piece of Letter>로 마무리되는 이 시리즈는 “자연스럽게 개인적인 기억들을 들여다보고 그것들이 어떻게 구성되어 있고 왜곡되어 있는지 확인하는 과정 자체”를 보여 주고 있다. 찰나의 순간을 ‘기록’하는 사진의 태생적 임무를 넘어서 윤태준의 사진 작업은 작가의 시선이 개입되는 ‘개인적인 기억’으로 파고든다. 한편, 과거 작가는 음식물을 시험관 안에 보관해 특정 사진과 함께 선보인 바 있는데, 교환 학생 프로그램으로 독일에서 체류하던 시간은 오롯이 보존하고 싶어 자신의 신체와 에너지를 담은 음식물들을 수집한 것이었다. <회한의 무게> 시리즈 이후, 작가는 이 시리즈의 사진 자체에서 추출한 잉크로 종이 위에 또 다른 사진을 프린트하는 작업을 진행하고 있다.</p>

      `,
      contentINFO: `아트인컬쳐, New Look, 8월호, 2015<br/>
      탁영준`
    },
    
  ];
  