<template>
  <div class="gallery-page">
    <div class="work-title">
        <h5>{{ episodesData.title }}</h5>
      </div>
    <div class="image-section" @wheel.prevent="handleScroll">
      <div class="gallery-container">
        <div class="gallery-wrapper">
          <div
            class="img-wrap"
            v-for="(episode, index) in episodesData.images"
            :key="episode.videoLink || index"
          >
            <!-- 이미지 렌더링 -->
            <img
              v-if="episode.type === 'image'"
              :src="episode.thumbnail"
              :alt="`Image ${index + 1}: ${episode.description}`"
              class="responsive-image fade-in"
              @error="setDefaultImage($event)"
            />
            <!-- iframe 렌더링 -->
            <div
              v-else-if="episode.type === 'video'"
              class="video-wrapper"
            >
              <iframe
                :src="`https://www.youtube.com/embed/${extractYouTubeId(episode.videoLink)}?autoplay=1`"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="responsive-video"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-section">
      <h5>작가노트 Artist's statement</h5>
      <div v-html="episodesData.statement"></div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { imagesData } from '@/data/images.js';

export default {
  name: 'ImageCompView',
  setup() {
    const route = useRoute();

    // 에피소드 데이터를 가져옵니다.
    const episodesData = computed(() => {
      const data = imagesData[route.params.workId];
      if (data) {
        return data;
      } else {
        console.error(`No data found for workId: ${route.params.workId}`);
        return {
          images: [],
          title: '등록된 제목이 없습니다.',
          statement: '등록된 아티스트 스테이트먼트가 없습니다.',
        };
      }
    });

    // YouTube 비디오 ID 추출 함수
    const extractYouTubeId = (url) => {
      const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/))([\w-]{11})/);
      return match ? match[1] : '';
    };

    // 이미지가 로드되지 않을 경우 기본 이미지를 설정하는 함수
    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png');
      event.target.classList.add('error-image');
    };

    // 좌우 스크롤 이벤트 처리
    const handleScroll = (event) => {
      const container = event.currentTarget;
      const delta = event.deltaY || event.deltaX;
      container.scrollLeft += delta; // 좌우 스크롤 변경
    };

    return {
      episodesData,
      extractYouTubeId,
      setDefaultImage,
      handleScroll,
    };
  },
};
</script>


<style scoped>
/* 스타일 코드 */

.gallery-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.gallery-wrapper {
  vertical-align: top;
  display: inline-block;
  margin: 0; /* 여백을 없애거나 */
  padding: 0; /* 여백을 최소화 */
}

.image-section {
  display: grid;
  grid-auto-flow: column; /* 가로로 흐르게 설정 */
  grid-auto-columns: 550px; /* 각 아이템의 기본 너비 */
  gap: 10px; /* 각 이미지/비디오 간격 */
  overflow-x: auto; /* 좌우 스크롤 활성화 */
  padding-bottom: 10px; /* 스크롤 시 하단 여백 */
  max-height: 100vh;
  position: relative;
  margin-top: 10px;
  width: 100%;
  white-space: nowrap; /* 줄바꿈 방지 */
  scroll-snap-type: x mandatory; /* 스크롤 스냅 추가 (옵션) */
}


.work-title {
  text-align: left;
  top: 0;
  z-index: 1;
  padding-bottom: 10px;
}

.text-section {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-top: 10px;
  width: 100%;
  overflow-y: auto;
  border-left: 2px dashed #cfff00;
}

.img-wrap {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
}

.img-wrap img {
  width: 100%;
  object-fit: contain;
}

.responsive-image {
  max-width: 100%;
  max-height: 100%;
}

.video-wrapper {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
  width: 100%;
  max-width: 550px;
  height: 500px;
}

.responsive-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none;
}


/* 799x769 해상도 이하에서 이미지 크기 조정 */
@media (max-width: 768px) {
  .responsive-image {
    max-height: 400px;
  }
  .video-wrapper {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
  width: 100%;
  max-width: 550px;
  height: 400px;
  }
  .image-section {
    grid-auto-columns: 300px; /* 작은 화면에서 각 아이템의 너비 조정 */
    gap: 5px; /* 작은 화면에서 간격 줄이기 */
  }
}

@media (max-width: 1200px) {
  .gallery-page {
    flex-direction: column;
  }
  .image-section {
    overflow-x: auto;
  }
  .image-section {
    grid-auto-columns: 400px; /* 중간 화면에서 각 아이템의 너비 조정 */
    gap: 8px; /* 중간 화면에서 간격 조정 */
  }

  .responsive-image {
    align-items: flex-start;
  }
  .img-wrap img {
    display: block;
    width: 550px;
    object-fit: contain;
  }
  .text-section {
    width: 100%;
  }
}



</style>