<template>
<RecentCompView :episodes="episodes" :shuffle="false" />
</template>


<script>
import RecentCompView from '@/components/RecentCompView.vue';

export default {
  components: {
    RecentCompView
  },
  data () {
    return {
        episodes: [
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_01.jpg'),
              description: '장님, 코끼리 만지듯이, 지금여기, 서울, 한국, 2015년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_02.jpg'),
              description: '장님, 코끼리 만지듯이, 지금여기, 서울, 한국, 2015년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_03.jpg'),
              description: '장님, 코끼리 만지듯이, 지금여기, 서울, 한국, 2015년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_04.jpg'),
              description: '포토아일랜드 페스티벌 2015 지금, 더 사우스 스튜디오스, 더블린, 아일랜드, 2015년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_07.jpg'),
              description : 'New Generation – SIZAK, 라즈니아 현대 미술관 센터, 그단스크, 폴란드, 2016년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_08.jpg'),
              description : 'New Generation – SIZAK, 라즈니아 현대 미술관 센터, 그단스크, 폴란드, 2016년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_09.jpg'),
              description: 'Slices On Time, 빌라 로마나 하우스, 피렌체, 이탈리아, 2016년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_010.jpg'),
              description: 'Slices On Time, 빌라 로마나 하우스, 피렌체, 이탈리아, 2016년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_011.jpg'),
              description : '환상계단, 커뮤니티 아트 2017, 안녕하세요, 서울시립 북서울미술관, 서울, 한국, 2017'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_013.jpg'),
              description : '환상계단, 커뮤니티 아트 2017, 안녕하세요, 서울시립 북서울미술관, 서울, 한국, 2017'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_017.jpg'),
              description : '캔 창작자 워크숍 참여 C2A, Can Foundation, 서울, 2020년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_018.jpg'),
              description : '캔 창작자 워크숍 참여 C2A, Can Foundation, 서울, 2020년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_019.jpg'),
              description : '미들턴 Middle turn, 공간 X Shift, 서울, 한국, 2021년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_020.jpg'),
              description : '미들턴 Middle turn, 공간 X Shift, 서울, 한국, 2021년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_021.jpg'),
              description : '미들턴 Middle turn, 공간 X Shift, 서울, 한국, 2021년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_022.jpg'),
              description : '2016 New Generation – SIZAK, 라즈니아 현대 미술관 센터, 그단스크, 폴란드'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_023.jpg'),
              description : '2016 New Generation – SIZAK, 라즈니아 현대 미술관 센터, 그단스크, 폴란드'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_024.jpg'),
              description : '끝 다음에 도착한, 산수싸리, 광주, 한국, 2021년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_025.jpg'),
              description : '끝 다음에 도착한, 산수싸리, 광주, 한국, 2021년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_026.jpg'),
              description : '끝 다음에 도착한, 산수싸리, 광주, 한국, 2021년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_027.jpg'),
              description : '사진의 경계, 광주시립 사진전시관, 광주, 한국, 2022년'

             },   
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_028.jpg'),
              description : '사진의 경계, 광주시립 사진전시관, 광주, 한국, 2022년'

             },   
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_029.jpg'),
              description : '사진의 경계, 광주시립 사진전시관, 광주, 한국, 2022년'

             },
                {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_030.jpg'),
              description : '길위에서, 5.18기념문화재단, 광주, 한국, 2022년'
             },
                {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_031.jpg'),
              description : '길위에서, 5.18기념문화재단, 광주, 한국, 2022년'

             },
                {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_032.jpg'),
              description : '지금 우리는 어디에?, 성곡미술관, 한국, 서울, 2022년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_033.jpg'),
              description : '지금 우리는 어디에?, 성곡미술관, 한국, 서울, 2022년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_034.jpg'),
              description : 'Spaceless, 주한 스위스 대사관, 서울 한국, 2022년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_035.jpg'),
              description : 'Spaceless, 주한 스위스 대사관, 서울 한국, 2022년'

             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_036.jpg'),
              description : 'Spaceless, 제14회 광주비엔날레, 스위스파빌리온, 광주, 한국, 2023년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_037.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_038.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_039.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_040.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_041.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
             {type:'image',
              thumbnail: require('@/assets/img/pro/show/show_042.jpg'),
              description : '워터 포토아우토맛, 스페에스 디디에프, 광주, 한국, 2024년'
             },
            {type : 'image',
              thumbnail: require('@/assets/img/pro/proe_1.jpg'),
              description: 'Artnow Magazine, 아이템 섹션 광고 지면 촬영, 2019년, 26호'
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/proe_2.jpg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/proe_3.jpg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/proe_4.jpg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_01.jpeg'),
              description: 'Big Watch Book, Esquire, 2023년, 10월호'
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_02.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_03.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_04.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_05.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_06.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_07.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_08.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/commer_09.jpeg'),
            },{type : 'image',
              thumbnail: require('@/assets/img/pro/arch/arch_01.jpg'),
              description: '담양, 전라님도'
            },
            {type : 'image',
            thumbnail: require('@/assets/img/pro/arch/arch_03.jpg'),
            },
            {type : 'image',
            thumbnail: require('@/assets/img/pro/arch/arch_02.jpg'),
            },
            {type : 'image',
            thumbnail: require('@/assets/img/pro/arch/arch_04.jpg'),
            description: '도남동, 제주특별자치도'
            },
            {type : 'image',
            thumbnail: require('@/assets/img/pro/arch/arch_05.jpg'),
            },
            {type : 'image',
            thumbnail: require('@/assets/img/pro/arch/arch_06.jpg'),
            },

            ]
    }
  }
}
</script>

<style scoped>  

</style>
