<template>
  <div class="container">
    <div 
      v-for="(episode, index) in episodesToDisplay" 
      :key="episode.id || index"
      class="img-wrap"
      :style="getDelayStyle(index)"
    >
      <!-- 이미지 렌더링 -->
      <img 
        v-if="episode.type === 'image'" 
        :src="placeholderImage"
        :data-src="episode.thumbnail" 
        :alt="episode.description" 
        class="responsive-media fade-in" 
        ref="images"
        @error="setDefaultImage"
      >
      
      <!-- 비디오 렌더링 -->
      <div v-else-if="episode.type === 'video'" class="video-wrapper">
        <iframe 
          :src="convertYoutubeLink(episode.videoLink)" 
          class="responsive-media video fade-in" 
          frameborder="0" 
          allowfullscreen
        ></iframe>
        <span v-if="episode.videoLink" class="video-link">
          <a :href="episode.videoLink" target="_blank" rel="noopener noreferrer">
            Watch Video
          </a>
        </span>
      </div>
      
      <!-- 설명 텍스트 -->
      <span v-if="episode.description" class="description">{{ episode.description }}</span>
    </div>
  </div>
</template>


<script>
import placeholderImage from '@/assets/placeholder-image.png';

export default {
  name: 'RecentCompView',
  props: {
    episodes: {
      type: Array,
      required: true
    },
    shuffle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      placeholderImage: placeholderImage,
      episodesToDisplay: []
    };
  },
  mounted() {
    this.prepareEpisodes();
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  methods: {
    setDefaultImage(event) {
      event.target.src = this.placeholderImage;
      event.target.classList.add('error-image');
    },
    convertYoutubeLink(link) {
      return link.replace('watch?v=', 'embed/');
    },
    getDelayStyle(index) {
      return { animationDelay: `${index * 100}ms` };
    },
    prepareEpisodes() {
      if (this.shuffle) {
        this.episodesToDisplay = [...this.episodes].sort(() => Math.random() - 0.5);
      } else {
        this.episodesToDisplay = this.episodes;
      }
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      });

      this.$refs.images.forEach(img => {
        if (img && img.tagName === 'IMG') observer.observe(img);
      });
    },
    handleIntersection(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const highResSrc = img.getAttribute('data-src');
          if (highResSrc) {
            const tempImg = new Image();
            tempImg.src = highResSrc;
            tempImg.onload = () => {
              img.src = highResSrc;
              img.classList.add('loaded');

              img.parentElement.style.aspectRatio = `${tempImg.naturalWidth} / ${tempImg.naturalHeight}`;

              observer.unobserve(img);
            };
          }
        }
      });
    }
  }
};
</script>


<style scoped>
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 5px;
}

.img-wrap {
  position: relative;
  overflow: hidden;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
  width: 100%;
  aspect-ratio: 4 / 3; /* 비디오와 이미지 모두 동일한 비율 유지 */
}

.responsive-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: opacity 0.5s ease;
}

.responsive-media.loaded {
  opacity: 1;
}

.description {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 11px;
  max-width: 80%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 비디오 링크 스타일 */
.video-link {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
}


/* 반응형 조정 */
@media screen and (max-width: 768px) {
  .container { grid-template-columns: repeat(1, 1fr); }
  .description { max-width: calc(100% - 40px); }
}

@media screen and (max-width: 480px) {
  .container { grid-template-columns: 1fr; }
}

</style>