<template>
  <div class="content-detail-page">
    <div v-if="contentItem.thumbnails" class="content-image">
      <img
        v-for="(thumbnail, index) in contentItem.thumbnails"
        :key="index"
        :src="thumbnail"
        :alt="contentItem.description"
      />
    </div>
    <div class="content-text">
      <h4 v-if="contentItem.title">{{ contentItem.title }}</h4>
      <div v-html="contentItem.description"></div> <!-- v-html 사용 -->
      <!-- 연도 표시 -->
      <span v-if="contentItem.year"> {{ contentItem.year }}</span>
    </div>
    <div><h5 style="font-weight:400; cursor: pointer; border-bottom: 1px solid #cfff00;" @click="goBack"> 돌아가기</h5></div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import imagesData from '@/data/images.js';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const workId = route.params.workId;
    const contentId = parseInt(route.params.contentId);

    const workData = computed(() => imagesData[workId] || { images: [], content: [] });
    const contentItem = computed(() => workData.value.combinedContent[contentId] || {});

    const goBack = () => {
      router.back();
    };

    return {
      workData,
      contentItem,
      goBack,
    };
  },
};
</script>


<style scoped>

.content-detail-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.content-image {
  display: grid;
  gap: 10px;
  max-width: 500px;
  margin-bottom: 20px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

/* 공통 스타일 */
.content-image img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

/* 이미지 개수에 따른 스타일 */
.content-image img:nth-child(1):only-child {
  width: 500px;
}

.content-image img:nth-child(-n+2):only-of-type,
.content-image img:nth-child(3):nth-last-child(1) {
  width: 240px;
}

.content-image img:nth-child(3):last-child,
.content-image img:nth-child(5):last-child {
  width: 500px;
  grid-column: 1 / -1; /* 가로로 꽉 채우도록 설정 */
}

/* 반응형: 작은 화면일 때 전체 너비로 */
@media (max-width: 500px) {
  .content-image {
    grid-template-columns: 1fr;
  }
}

.content-text {
  max-width: 500px;
}

/* 반응형: 작은 화면일 때 전체 너비로 */
@media (max-width: 500px) {
  .content-image {
    grid-template-columns: 1fr;
  }

  .content-image img {
    width: 100%; /* 이미지가 전체 너비를 차지하도록 설정 */
    max-width: 100%; /* 최대 너비 제한 */
    height: auto;
  }

  .content-text {
    width: 100%; /* 텍스트 영역도 전체 너비로 설정 */
    max-width: 100%;
  }
}

</style>