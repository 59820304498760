<!-- 20240918_메인페이지 main page -->

<template>
  <div style="margin: 0;">
    <!-- Network 메뉴 직접 추가 -->
    <!-- <div class="menu-item">
      
    </div> -->
    <div class="menu-item">
      <a target="_blank" href="https://drive.google.com/file/d/1FKPvhg1uDHDEkxBBNY7NFE3FobgCWk7n/view?usp=drive_link">
      Portfolio Download
      </a>
    </div>
    <div class="menu-item"> 
      <router-link class="menu-title" to="/research/network" style=" border-bottom: 1px dashed #cfff00; color: #cfff00; font-size: 14px;">
        이미지 연구-네트워크, Image Research, Network
      </router-link>
    </div>
    <h5>Recent Work</h5>
    <RecentCompView :episodes="episodes" />
    <!-- Portfolio Download 링크 -->
  </div>
</template>

<script>
import { ref } from 'vue';
import RecentCompView from '@/components/RecentCompView.vue';
import { imagesData } from '@/data/images.js';

export default {
  name: 'MainView',
  components: { RecentCompView },
  setup() {
    const episodes = ref(imagesData.Recent.images); // 데이터 가져오기

    return {
      episodes
    };
  }
};
</script>

<style scoped>
.menu-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  margin-left: 0;
}

.container-wrap {
  display: flex;
  flex-direction: column;
}
.front-img {
  width: 100%;
  max-width: 500px;
  min-width: 320px;
}
</style>
