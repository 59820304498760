<template>
  <div class="network-gallery-page">
    <div class="network-image-section" @wheel.prevent="handleScroll">
      <div class="network-work-title">
        <h5>{{ networkData.title }}</h5>
      </div>
      <div class="network-gallery-container">
        <div class="network-gallery-wrapper">
          <!-- 이미지와 타이틀을 하나의 래퍼로 감싸고 클릭 이벤트를 여기에 적용 -->
          <div 
            class="network-img-wrap" 
            v-for="(item, index) in networkData.combinedContent" 
            :key="item.thumbnail || item.videoLink || index"
            @click="item.thumbnails || item.videoLink ? goToContentDetail(index) : null"
            :style="{ cursor: item.thumbnails || item.videoLink ? 'pointer' : 'default', pointerEvents: item.thumbnails || item.videoLink ? 'auto' : 'none' }"
          >
            <!-- 이미지 혹은 비디오 렌더링 -->
            <img v-if="item.type === 'image'" :src="item.thumbnail" :alt="item.description" class="network-responsive-image fade-in" @error="setDefaultImage($event)">
            <iframe v-else-if="item.type === 'video'" :src="item.videoLink" frameborder="0" allowfullscreen class="network-video fade-in"></iframe>
            
            <!-- 타이틀 표시: item.title이 있을 경우에만 표시 -->
            <div v-if="item.title" class="network-image-title">
              <h4 style="font-weight: 400;">{{ item.title }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 새로운 텍스트 및 이미지 컨테이너 -->
    <div class="network-text-container">
      <div class="network-text-section">
        <h5>작가노트 Artist's statement</h5>
        <div v-html="networkData.statement"></div> <!-- v-html 사용 -->
      </div>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { imagesData } from '@/data/images.js';

export default {
  setup() {
    const router = useRouter();

    const networkData = computed(() => imagesData.Network || { images: [], title: '제목 없음', statement: '설명 없음.' });

    const handleScroll = (event) => {
      const container = event.currentTarget;
      container.scrollLeft += event.deltaY;
      container.scrollLeft += event.deltaX;
    };

    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png');
      event.target.classList.add('error-image');
    };

    const goToContentDetail = (index) => {
  router.push({
    name: 'ContentDetailView',
    params: {
      workId: 'Network',
      contentId: index
    }
  });
};

    return {
      networkData,
      handleScroll,
      setDefaultImage,
      goToContentDetail
    };
  }
}
</script>

<style scoped>


.network-gallery-container {
  margin-bottom: 10px;
  padding-top: 5px;
  width: 90vw;  
  /* 변경하지 말것 */
}

.network-gallery-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.network-image-section {
  overflow-x: auto;
  white-space: nowrap;
}

.network-work-title {
  text-align: left;
  top: 0;
  z-index: 1;
  margin-top: 10px;
}

.network-text-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.network-text-section, .network-images-section, .research-section {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-left: 2px dashed #cfff00;
}

.network-img-wrap {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
  cursor: pointer;
}

  /* 이미지 크기 */
.network-responsive-image {
  max-width: 650px;
  object-fit: cover;
}

.network-video {
  width: 800px !important;
  aspect-ratio: 16 / 9 ;
  object-fit: cover !important;
}



/* 가로 이미지에 대해 다른 스타일 적용 */
.network-responsive-image.landscape {
  width: 100%;
  max-width: 1000px; /* 가로 이미지를 더 크게 */
  height: auto;
}

/* 이미지 크기 */
.network-responsive-image {
  width: 100%; /* 컨테이너 너비에 맞춰 100% */
  height: auto; /* 높이는 자동으로 설정 */
  max-width: 700px; /* 최대 너비 설정 */
  aspect-ratio: 3 / 4; /* 가로세로 비율을 3:4로 고정 (세로가 더 긴 비율) */
  object-fit: cover; /* 컨테이너에 맞게 이미지 자르기 */
}

.network-responsive-image:hover,
.network-video:hover {
  filter: invert(0.8);
  transition: filter 0.5s ease; /* 트랜지션 추가 */
}

.network-image-title {
  padding-top: 5px;
  font-size: 14px;
  color: black;
  z-index: 100;
}

@media (min-width: 1024px) {
  .network-responsive-image, .research-image {
    max-width: 520px; /* 큰 화면에서 최대 너비 */
    aspect-ratio: 3 / 4; /* 동일한 비율 유지 */
  }
}

@media (max-width: 768px) {
  .network-gallery-page {
    flex-direction: column;
  }
  .network-responsive-image, .research-image {
    max-width: 350px;
  }
  .network-text-section {
    order: -1;
  }
}
</style>