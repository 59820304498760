// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/components/MainView.vue';
import CvCompView from '@/components/CvCompView.vue';
import ArtStateView from '@/components/ArtStateView.vue';
import ImageCompView from '@/components/ImageCompView.vue';
import AproComp from '@/components/project/AproComp.vue';
import NetworkGalleryView from '@/components/work/NetworkGalleryView.vue';
import ContentDetailView from '@/components/work/ContentDetailView.vue'; // 상세 페이지 컴포넌트 추가

const routes = [
  { path: '/', name: 'MainView', component: MainView, meta: { title: 'TaejunYun 윤태준', description: '메인 페이지 설명입니다.' } },
  { path: '/about', name: 'CvCompView', component: CvCompView, meta: { title: 'CV', description: '소개 페이지 설명입니다.' } },
  { path: '/text', name: 'ArtStateView', component: ArtStateView, meta: { title: 'Text', description: '텍스트 페이지 설명입니다.' } },
  { path: '/work/:workId', name: 'ImageCompView', component: ImageCompView, meta: { title: 'Works', description: '작업 페이지 설명입니다.' } },
  { path: '/project', name: 'AproComp', component: AproComp, meta: { title: 'Intallation&etc', description: '프로젝트 페이지 설명입니다.' } },
  { path: '/research/:workId', name: 'NetworkGalleryView', component: NetworkGalleryView, meta: { title: 'Networks Research', description: '연구 페이지 설명입니다.' } },
  {
    path: '/research/:workId/content/:contentId', // 변경된 경로
    name: 'ContentDetailView',
    component: ContentDetailView,
    meta: { title: 'Research', description: '상세 페이지 설명입니다.' },
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});


export default router;
